import React from 'react';
import ReactDOM from 'react-dom';
import { createGlobalStyle, ThemeProvider } from 'styled-components';
import { ApolloProvider } from '@apollo/react-hooks';
import ApolloClient from 'apollo-boost';

import Routes from './routes';
import * as serviceWorker from './serviceWorker';

import theme from 'styles/theme';

import * as Sentry from '@sentry/browser';
Sentry.init({
	dsn:
		process.env.SENTRY_DNS,
});

require('intersection-observer');

const GlobalStyle = createGlobalStyle`
  * {
    box-sizing: border-box;
  }
  body {
    font-family: 'Roboto', sans-serif;
    line-height: 24px;
  }
  .sc-fAjcbJ  {
    padding-top:0px !important;
    padding-bottom:0px !important;
  }
`;

const client = new ApolloClient({
	uri: `${process.env.REACT_APP_API_URL}/`,
});

ReactDOM.render(
	<ApolloProvider client={client}>
		<ThemeProvider theme={theme}>
			<>
				<GlobalStyle />
				<Routes />
			</>
		</ThemeProvider>
	</ApolloProvider>,
	document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
