import React, { lazy, Suspense } from 'react'
import { BrowserRouter as Router, Route, Switch, Redirect } from 'react-router-dom'

const PreviewProfile = lazy(() => import('views/PreviewProfile'))
const EditProfile = lazy(() => import('views/EditProfile'))
const Login = lazy(() => import('views/Login'))
const Verify = lazy(() => import('views/Verify'))
const Dashboard = lazy(() => import('views/Homepage'))

const Routes = () => {
  return (
    <Router>
      <Switch>
        <Route exact path="/" component={props => (
          <Suspense fallback="...">
            <Login {...props} />
          </Suspense>
        )} />
        <Route path="/profile/:id/preview" component={props => (
          <Suspense fallback="...">
            <PreviewProfile {...props} />
          </Suspense>
        )} />
        <Route path="/profile/:id/edit" component={props => (
          <Suspense fallback="...">
            <EditProfile {...props} />
          </Suspense>
        )} />
        <Route path="/verify/:token" component={props => (
          <Suspense fallback="...">
            <Verify {...props} />
          </Suspense>
        )} />
        <Route path="/dashboard" component={props => (
          <Suspense fallback="...">
            <Dashboard {...props} />
          </Suspense>
        )}/>
        <Redirect to="/" />
      </Switch>
    </Router>
  )
}

export default Routes
